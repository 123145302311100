import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { BsX } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { SET_RUNNING_CALLS, SET_VIEWER_IMAGES, SHOW_LIST_ONGOING, START_JITSI_CALL } from '../store';
import { BASE_URL, KJUUP_COLORS } from '../helper/constants';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import VideoPlayers from './VideoPlayers';
import ReactPlayer from 'react-player'
import { ImPhone, ImPhoneHangUp } from 'react-icons/im';
import Lottie from 'react-lottie';
import animationData from '../../src/Icons/PhoneCall.json';
import AcceptCallButton from './AcceptCallButton';


function OngoingCallsListPopup({ reduxImages }) {
    const dispatch = useDispatch()
    const showListOngoing = useSelector(e => e.showListOngoing)
    const runningCalls = useSelector(e => e.runningCalls)
    const user = useSelector(e => e.user)
    const getProjectName = (name) => {
        if (name) {
            // Remove all non-alphabetic characters (keep only letters)
            const cleanedName = name.replace(/[^a-zA-Z]/g, "");
            return cleanedName.substring(0, 2).toUpperCase();
        }
        return "";
    };
    const onClickOfEndButton = (group) => {
        let newRunningCalls = runningCalls.filter(e => e.groupId != group.groupId);
        if (newRunningCalls.length === 0) {
            dispatch({ type: SHOW_LIST_ONGOING, payload: false })
        }
        dispatch({ type: SET_RUNNING_CALLS, payload: newRunningCalls })
    }
    const _startJitsiCall = (group) => {
        dispatch({ type: START_JITSI_CALL, payload: { roomId: group.groupId, displayName: user.name } })

    }


    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
        }
    };


    return (
        showListOngoing ?
            <div style={{ position: "absolute", left: 0, right: 0, bottom: 0, top: 0, zIndex: 1000, background: 'rgba(0,0,0,0.5)', display: "flex", flexDirection: "column" }}>
                <div onClick={() => {
                    localStorage.setItem("videoCallPop", true)
                    dispatch({ type: SHOW_LIST_ONGOING, payload: false })
                }} style={{ position: "absolute", alignSelf: "flex-end", right: 0 }}>
                    <div style={{ marginTop: 20, marginRight: 20 }}>
                        <BsX color='#fff' size={40} />
                    </div>
                </div>
                <div className="chat-list-container" style={{}}>
                    {runningCalls.map((group) => (
                        <div className="chat-group" key={group.id}>
                            <div className="icon-container">{getProjectName(group.buildingName)}</div>
                            <div className="chat-info">
                                <span className="chat-title">{`Anruf in der Chat Gruppe ${group.groupName} im Raum ${group.buildingName} gestartet.`}</span>
                            </div>
                            <div onClick={() => {
                                _startJitsiCall(group)
                            }} style={{ cursor: 'pointer' }}>
                                {/* <Lottie
                                    options={defaultOptions}
                                    height={40}
                                    width={20}
                                    isStopped={false}
                                    isPaused={false}
                                /> */}
                                <AcceptCallButton />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            :
            <></>
    )
}

export default OngoingCallsListPopup
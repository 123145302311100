import React, { memo, useContext, useEffect, useState } from "react";
import { Badge, Form } from "react-bootstrap";
import { callAPI } from "../../helper/apiUtils";
import ShareProjectMapDialog from "../../components/ProjectMapShare/ShareProjectMapDialog";
import LanguageContext from "../../helper/LanguageContext";
import { $crud } from "../../helper/CrudFactory";
import { DE } from "../../Languages/DE";
import { EN } from "../../Languages/EN";
import { Scrollbars } from "react-custom-scrollbars";
import { connect, useDispatch, useSelector } from "react-redux";
import { BsArrowLeft, BsChevronLeft, BsChevronRight, IoIosNotificationsOutline } from "react-icons/all";
import {
  OPEN_BOTTOM_PROJECTS,
  OPEN_PROJECTS,
  PROJECT_REQUEST_COUNT,
  PROJECTS,
  UPDATE_PROJECT_ACTION_FIRE,
  IS_OPEN_BY_OWNER,
  ALL_BADGE_COUNT,
  INTERNAL_PROJECTS,
  SET_JOIN_REQUEST_EXTERNAL,
  SET_TAB,
  SET_JOIN_REQUEST_INTERNAL,
  SET_COMPANY_IMAGE,
  SET_COMPANY_ID,
  RESET_MARKIN_PROJECT,
} from "../../store";
import { Link, withRouter } from "react-router-dom";
import ProjectNotification from "./ProjectNotification";
import CompanyListDialog from "./Dialogs/CompanyListDialog";
import { getLightColor, KJUUP_COLORS, TOGGLE_DRAWER } from "../../helper/constants";
import ProjectListComponent from "./ProjectListComponent";
import PropTypes, { element } from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import ProjectListComponentInternal from "./ProjectListComponentInternal";
import IGRENN from '../../Icons/InternalGreen.png';
import INormal from '../../Icons/Internal-Project.png';
import ExternalOrange from '../../Icons/ExternalOrange.png';
import ExternalNormal from '../../Icons/External-Project.png';
import { SocketContext } from "../../context/socket";
import { ReactComponent as AddProjectIcon } from '../../Icons/Add project-deactivated.svg';
import CommonHeader from "../../components/CommonHeader";
import { useHistory } from "react-router-dom";
import InvitationProjectsCard from "../../components/InvitationProjectsCard";

function a11yProps(index, value) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    style: {
      // backgroundColor: (index == 0 && value == index) ? "yellow" : (index == 1 && value == index) ? "blue" : "white",
      color: (index == 1 && value == index) ? "#288f6c" : (index == 0 && value == index) ? "#f08438" : "grey",
      borderWidth: 0,
      padding: 0,
      textTransform: "none",
      fontSize: "1.8vh",
      bottom: 7,
      // fontWeight: "200"
    }
  };
}


const renderThumb = ({ style, ...props }) => {
  const thumbStyle = {
    borderRadius: 6,
    backgroundColor: "rgba(240, 132, 56, 0.8)",
  };
  return <div style={{ ...style, ...thumbStyle }} {...props} />;
};

const CustomScrollbars = (props) => (
  <Scrollbars
    renderThumbHorizontal={renderThumb}
    renderThumbVertical={renderThumb}
    {...props}
  />
);



function MyProject(props) {


  const [params, setParams] = useState({
    companyId: "",
    id: "",
    companySelect: false,
  });
  const [value, setValue] = React.useState(0);
  const { isGerm } = React.useContext(LanguageContext);
  const [show, setShow] = useState(false);
  const [projectDetails, setProjectDetails] = useState({});
  const [allProjects, setAllProject] = useState([]);
  const [projects, setProjects] = useState([]);
  const [PlusProject, setKjuupPlusProjects] = useState([]);
  const [PlusProjectAll, setAllKjuupPlusProjects] = useState([]);
  const [showCompanyDialog, setCompanyDialog] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [search, setSearch] = useState("");
  const [pendingRequests, setPendingRequests] = useState([]);
  const [pendingRequestsPlus, setPendingRequestsPlus] = useState([]);
  const [showNotification, setNotification] = useState(false);
  const [plusNotifications, setPlusNotifications] = useState(false);
  const joinRequestsInternal = useSelector(state => state.joinRequestsInternal)
  const joinRequestsExternal = useSelector(state => state.joinRequestsExternal)
  const selectedCompanyId = useSelector(state => state.selectedCompanyId)
  const { unSeenAllCount } = useSelector((state) => state);
  const [internalProkjects, setInternalProjects] = useState([]);
  const [shouldShowTabWise, setShouldShowTabWise] = useState(false);
  const Socket = useContext(SocketContext);
  const user = useSelector((state) => state.user);
  const [disableProjet, setDisableProject] = useState(false)
  const history = useHistory();

  useEffect(() => {
    if (Socket && Socket.on) {
      _addRemoveProjectListener()
    }
  }, [Socket])

  // useEffect(() => {
  //   if (disableProjet) {
  //     let mdata = disableProjet
  //     if (mdata.user == user.id) {
  //       let tmp = [...projects];
  //       let disabledProjectsExternal = []
  //       tmp = tmp.map(e => {
  //         if (mdata.buildingIds.includes(e.id)) {
  //           e = { ...e, isJoin: false }
  //           disabledProjectsExternal.push(e)
  //         }
  //         return e;
  //       });
  //       tmp = tmp.filter(e => !mdata.buildingIds.includes(e.id))
  //       tmp = [...tmp, ...disabledProjectsExternal]
  //       setProjects([...tmp])
  //       setAllProject([...tmp])
  //       let tmpx = [...PlusProject]
  //       tmpx = tmpx.map(e => {
  //         if (e.userProjectList && e.userProjectList.length > 0) {
  //           let disabledProjectsInternal = []
  //           e.userProjectList = e.userProjectList.map(x => {
  //             if (mdata.buildingIds.includes(x.id)) {
  //               x = { ...x, isJoin: false }
  //               disabledProjectsInternal.push(x)
  //             }
  //             return x;
  //           })
  //           e.userProjectList = e.userProjectList.filter(x => !mdata.buildingIds.includes(x.id))
  //           e.userProjectList = [...e.userProjectList, ...disabledProjectsInternal]
  //         }
  //         return e;
  //       })
  //       setKjuupPlusProjects([...tmpx])
  //       setAllKjuupPlusProjects([...tmpx])
  //       console.log('------>leave', tmp, tmpx)
  //     }
  //     setDisableProject(false)
  //   }
  // }, [disableProjet])

  const chat_click_plus = async (value, project, lemp,videoCallEnabled) => {
    let ind = -1;
    if (shouldShowTabWise && lemp == 0) {
      ind = PlusProject.findIndex(e => e.isSelected)
    }
    props.isOwnerOpenProject(false);
    const chkBtm = props.openBottomProjects.find((p) => p.id === project.id);
    if (!chkBtm) {
      const chk = props?.openProjects?.find((p) => p.id === project.id);
      if (chk) {
        const updateProjects = props.openProjects?.filter(
          (p) => p.id !== project.id
        );
        const TT = await updateProjects?.map((p) => {
          return { ...p, isInternal: true, videoCallEnabled };
        });
        props.updateOpenProjects(TT);
        const updateGroup = await projects?.map((p) => {
          if (p.id === project.id) {
            return { ...p, isOpened: false, viewByOwner: false, isInternal: true, videoCallEnabled };
          } else {
            return { ...p, videoCallEnabled };
          }
        });
        setAllProject(updateGroup);
        setProjects(updateGroup);
        props.updateProjects(updateGroup);
      } else {
        const updateProjects = [...props?.openProjects, project]
        console.log(updateProjects)
        const TT = await updateProjects?.map((p) => {
          return { ...p, isInternal: true, videoCallEnabled };
        });
        props.updateOpenProjects(TT);
        const updateGroup = await projects?.map((p) => {
          if (p.id == project.id) {
            return { ...p, isOpened: true, viewByOwner: false, isInternal: true, videoCallEnabled };
          } else {
            return { ...p, isInternal: true, videoCallEnabled };
          }
        });
        setAllProject(updateGroup);
        setProjects(updateGroup);
        props.updateProjects(updateGroup);
      }
    } else {
      console.log('----elser')
      //Hide from top section
      const updateProjects = props.openBottomProjects.filter(
        (p) => p.id !== project.id
      );
      const updateGroup = await projects.map((p) => {
        if (p.id === project.id) {
          return { ...p, isOpened: false, viewByOwner: false, isInternal: true, videoCallEnabled };
        } else {
          return { ...p, isInternal: true, videoCallEnabled };
        }
      });
      props.updateBottomOpenProjects(updateProjects);
      props.updateProjects(updateGroup);
    }
  };


  const chat_click = async (value, project, lemp) => {
    let ind = -1;
    if (shouldShowTabWise && lemp == 0) {
      ind = PlusProject.findIndex(e => e.isSelected)
    }
    props.isOwnerOpenProject(false);
    const chkBtm = props.openBottomProjects.find((p) => p.id === project.id);
    if (!chkBtm) {
      const chk = props?.openProjects?.find((p) => p.id === project.id);
      if (chk) {
        const updateProjects = props.openProjects?.filter(
          (p) => p.id !== project.id
        );
        const TT = await updateProjects?.map((p) => {
          return { ...p, isInternal: false };
        });
        props.updateOpenProjects(TT);
        const updateGroup = await projects?.map((p) => {
          if (p.id === project.id) {
            return { ...p, isOpened: false, viewByOwner: false, isInternal: false };
          } else {
            return { ...p, isInternal: false };
          }
        });
        setAllProject(updateGroup);
        setProjects(updateGroup);
        props.updateProjects(updateGroup);
      } else {
        const updateProjects = [...props?.openProjects, project]
        console.log(updateProjects)
        const TT = await updateProjects?.map((p) => {
          return { ...p, isInternal: false };
        });
        props.updateOpenProjects(TT);
        const updateGroup = await projects?.map((p) => {
          if (p.id == project.id) {
            return { ...p, isOpened: true, viewByOwner: false, isInternal: false };
          } else {
            return { ...p, isInternal: false };
          }
        });
        setAllProject(updateGroup);
        setProjects(updateGroup);
        props.updateProjects(updateGroup);
      }
    } else {
      console.log('----elser')
      //Hide from top section
      const updateProjects = props.openBottomProjects.filter(
        (p) => p.id !== project.id
      );
      const updateGroup = await projects.map((p) => {
        if (p.id === project.id) {
          return { ...p, isOpened: false, viewByOwner: false, isInternal: false };
        } else {
          return { ...p, isInternal: false };
        }
      });
      props.updateBottomOpenProjects(updateProjects);
      props.updateProjects(updateGroup);
    }
  };

  const _addRemoveProjectListener = () => {
    Socket.on('leaveCompany', function (mdata) {
      setDisableProject(mdata)
    });
  }

  const internalProjectsAll = useSelector(e => e.internalProjectsAll)
  const dispatch = useDispatch()
  useEffect(() => {
    console.log('---------->reset mark', props.resetProjectMarking)
    // if (props.resetProjectMarking) {
    let tmp = projects.map((e) => {
      return { ...e, isOpened: false }
    })
    let tmp2 = PlusProject.map((e) => {
      return { ...e, isOpened: false }
    })
    setProjects(tmp)
    setKjuupPlusProjects(tmp2)
    setAllKjuupPlusProjects(tmp2)
    setAllProject(tmp)
    // }
    props.resetMarking(false)
  }, [props.resetProjectMarking])
  useEffect(() => {
    console.log('--------------Join External', joinRequestsExternal)
    setPendingRequests(joinRequestsExternal)
    _ttm()
  }, [joinRequestsExternal])
  useEffect(() => {
    setPendingRequestsPlus(joinRequestsInternal)
  }, [joinRequestsInternal])
  const { userLoginToken } = useSelector((state) => state);
  const getCompanies = async () => {
    if (userLoginToken) {
      const { data } = await callAPI(`/company/my`, {}, "GET");
      if (data.status) {
        setCompanies(data.companies);
      }
    }
  };
  const getProjectJoinRequest = async () => {
    if (userLoginToken) {
      const { data } = await callAPI(
        `/building/getProjectJoinRequest`,
        {},
        "GET"
      );
      if (data.status) {
        dispatch({ type: SET_JOIN_REQUEST_EXTERNAL, requests: data.requests })
        props.updateProjectRequestCount(data.requests.length);
      }
    }
  };
  const setParam = async (name, value) => {
    setParams((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    if (PlusProject && PlusProject.length > 0 && selectedCompanyId) {
      console.log('==================>compiiiid', selectedCompanyId)
      let filteredArticles = PlusProject.map((e, i) => {
        if (selectedCompanyId !== null)
          return { ...e, isSelected: (e.id == selectedCompanyId) ? true : false }
        else
          return { ...e, isSelected: i == 0 ? true : false }
      })
      let ind = filteredArticles.findIndex(e => e.isSelected)
      if (ind !== -1) {
        apiRef.current?.scrollToItem(
          apiRef.current?.getItemById(filteredArticles[ind].id),
          // OR if you not sure about id for first item
          // myRef.current.getItemById(myRef.current.items.toItems()[0]),
          "smooth",
          "center"
        );
      }
      setKjuupPlusProjects([...filteredArticles])
      setAllKjuupPlusProjects([...filteredArticles])
    }
  }, [selectedCompanyId])

  const _ttm = () => {

  }

  const handleShow = async (project) => {
    setShow(true);
    setProjectDetails(project);
  };
  const handleClose = () => setShow(false);

  useEffect(()=>{
    if(userLoginToken){
      getProjects();
      getKjuupPlusProjects()
      getCompanies();
      getProjectJoinRequest();
    }
  },[userLoginToken])

  const getProjects = async () => {
    if (userLoginToken) {
      const { data } = await callAPI("/building/my", {}, "GET");
      if (data.status) {
        // let sortData = data.buildings.sort((a, b) => {
        //   return b.isJoin - a.isJoin;
        // });
        let sortData = await data.buildings.map((building, i) => {
          return { ...building, key: i + 1, isOpened: false };
        });
        const updateGroup = sortData.map((g) => {
          const chk = props.openProjects.find((c) => c.id === g.id);
          if (chk) {
            return { ...g, isOpened: true };
          } else {
            return { ...g };
          }
        });
        setAllProject(updateGroup);
        setProjects(updateGroup);
        props.updateProjects(updateGroup);
      }
    }
  };


  useEffect(() => {
    if (internalProkjects && internalProkjects.length && internalProkjects.length > 0) {
      setShouldShowTabWise(true)
      let all = [...internalProkjects]
      // for (let objx of all) {
      //   if (objx.userProjectList) {
      //     let isFirstList = true
      //     let prj = objx.userProjectList
      //     for (let obj of objx.userProjectList) {
      //       obj.isInternal = true
      //       obj.cID = objx.id
      //       obj.cId = objx.id
      //       obj.building = obj.id
      //       if (obj.seqNumber) {
      //         isFirstList = false
      //       }
      //     }
      //     if (isFirstList) {
      //       let joined = []
      //       let leaved = []
      //       for (let i = 0; i < prj.length; i++) {
      //         if (prj[i].isJoin) {
      //           joined.push(prj[i])
      //         }
      //         else {
      //           prj[i]['seqNumber'] = prj.length + 1
      //           leaved.push(prj[i])
      //         }
      //       }
      //       joined.reverse()
      //       for (let i = 0; i < joined.length; i++) {
      //         joined[i]['seqNumber'] = i + 1
      //       }
      //       let final = [...joined]
      //       for (let obj of leaved) {
      //         final.push(obj)
      //       }
      //       objx.userProjectList = [...final]
      //     }
      //     else {
      //       let joined = []
      //       let leaved = []
      //       for (let i = 0; i < prj.length; i++) {
      //         if (prj[i].isJoin) {
      //           joined.push(prj[i])
      //         }
      //         else
      //           leaved.push(prj[i])
      //       }
      //       joined.reverse()
      //       for (let i = 0; i < leaved.length; i++) {
      //         leaved[i]['seqNumber'] = prj.length + 1
      //       }
      //       let final = [...joined]
      //       for (let obj of leaved) {
      //         final.push(obj)
      //       }
      //       objx.userProjectList = [...final]
      //     }
      //   }
      // }
      setKjuupPlusProjects(all)
      setAllKjuupPlusProjects(all)
    }
    else {
      setShouldShowTabWise(false)
    }
  }, [internalProkjects])


  useEffect(() => {
    if (PlusProject.length > 0) {
      if (!PlusProject[0].projectJoinRequests) {
        getInternalJoinRequest(PlusProject[0].id, 0)
      }
    }
  }, [PlusProject])


  const getInternalJoinRequest = async (cId, index) => {
    const { data } = await callAPI(`/building/getProjectJoinRequest?companyId=${cId}`, {}, "GET");
    if (data.status) {
      let req = []
      for (let obj of data.requests) {
        let x = { ...obj, cId }
        req.push(x)
      }
      let tmp = [...PlusProject]
      tmp[index].projectJoinRequests = [...req]
      setKjuupPlusProjects(tmp)
    }
  };


  const getKjuupPlusProjects = async (ind) => {
    if (userLoginToken) {
      const { data } = await callAPI("/building/internalProjects", {}, "GET");
      console.log('------------>Kjuup+Proj', data, ind)
      if (data.status) {
        let all = [...data.foundUserCompany]
        if (all.length > 0) {
          let leaved = all.filter(e => e.isLeave)
          let joined = all.filter(e => !e.isLeave)
          // let _all = [...joined, ...leaved]
          let _all = [...all]
          let filteredArticles = _all.map((e, i) => {
            if (selectedCompanyId !== null)
              return { ...e, isSelected: (e.id == selectedCompanyId) ? true : false }
            else
              return { ...e, isSelected: i == 0 ? true : false }
          })
          let ind = filteredArticles.findIndex(e => e.isSelected)
          if (ind !== -1) {
            apiRef.current?.scrollToItem(
              apiRef.current?.getItemById(filteredArticles[ind].id),
              "smooth",
              "center"
            );
          }
          props.updateProjectsInternal(filteredArticles)
        }
      }
    }
  };

  useEffect(() => {
    if (!value) {
      props.changeBorder(KJUUP_COLORS.base_color)
    }
    else {
      props.changeBorder(KJUUP_COLORS.internal)
    }
  }, [value])
  useEffect(() => {
    setInternalProjects([...internalProjectsAll])
  }, [internalProjectsAll])

  useEffect(() => {
    props.changeBorder(!props.isExternalSelected ? KJUUP_COLORS.base_color : KJUUP_COLORS.internal)
    setValue(props.isExternalSelected)
  }, [props.isExternalSelected])


  // useEffect(() => {
  //   setTimeout(() => {
  //     let userDetails = localStorage.getItem("user")
  //     Socket?.on("building", (res) => {
  //       if (Object.keys(unSeenAllCount).length) {
  //         if (userDetails) {
  //           const isFind = res.user.find(item => item === JSON.parse(userDetails).id);
  //           if (isFind) {
  //             const chat = {
  //               add: -1,
  //               res: res
  //             };
  //             if (res.action === 'increase') {
  //               getProjects()
  //               let a = { ...unSeenAllCount }
  //               props.updateAllBadgeCount({ ...a, unseenBuildingChatCount: a.unseenBuildingChatCount + 1 });

  //             } else {
  //               getProjects()
  //               let a = { ...unSeenAllCount }
  //               props.updateAllBadgeCount({ ...a, unseenBuildingChatCount: a.unseenBuildingChatCount - 1 });
  //             }
  //           }
  //         }
  //       }
  //     });
  //   }, 500);
  // }, [!Object.keys(unSeenAllCount).length ? unSeenAllCount : ""])

  useEffect(() => {
    if (props.projects.length < 1) {
      // getProjects();
    } else {
      setProjects(props.projects);
      setAllProject(props.projects);
    }
  }, [props.projects]);



  useEffect(() => {
    if (props.updateProjectActionFire) {
      let ind = PlusProject.findIndex(e => e?.isSelected)
      getKjuupPlusProjects(ind)
      getProjects();
      props.updateProjectAction(false);
    }
  }, [props.updateProjectActionFire]);


  const getProjectName = (name) => {
    if (name) {
      // Remove all non-alphabetic characters (keep only letters)
      const cleanedName = name.replace(/[^a-zA-Z]/g, "");
      return cleanedName.substring(0, 2).toUpperCase();
    }
    return "";
  };
  //Handle Re-Join project
  const handleJoin = async (project) => {
    await $crud.confirm({
      title: "",
      textContent: isGerm ? `Wollen Sie dem Raum ${project.name} wirklich wieder beitreten?` : `Do you really want to re-join the room ${project.name}?`,
      options: {
        ok: isGerm ? DE.confirm : EN.confirm,
        cancel: isGerm ? DE.cancel : EN.cancel,
      },
    });
    if (companies.length > 1) {
      await setParam("id", project.id);
      setCompanyDialog(true);
    } else if (companies.length === 1) {
      await submitReJoinSingleCompany(project.id, companies[0].id);
    } else {
      await submitReJoinWithoutCompany(project.id);
    }
  };


  //Handle Re-Join project Internal
  const handleJoinInternal = async (project, id) => {
    await $crud.confirm({
      title: "",
      textContent: isGerm ? `Wollen Sie dem Raum ${project.name} wirklich wieder beitreten?` : `Do you really want to re-join the room ${project.name}?`,
      options: {
        ok: isGerm ? DE.confirm : EN.confirm,
        cancel: isGerm ? DE.cancel : EN.cancel,
      },
    });
    await submitReJoinSingleCompany(project.id, id);
  };


  const submitReJoinSingleCompany = async (project_id, companyId) => {
    const { data } = await callAPI(
      `/building/${params.id ? params.id : project_id}/join`,
      {
        id: project_id,
        companyId: companyId,
      },
      "PUT"
    );
    if (data.status) {
      $crud.notify({
        type: data.status,
        message: data.message,
      });
      const updateProjectList = props.openProjects.map((project) => {
        return project.id === params.id
          ? { ...project, isJoin: true }
          : project;
      });
      props.updateOpenProjects(updateProjectList);
      props.updateProjectAction(true);
      let ind = PlusProject.findIndex(e => e.isSelected)
      getKjuupPlusProjects(ind)
      getProjects();
    }
  };

  //Submit RE-join request
  const submitReJoinCompany = async (company) => {
    const { data } = await callAPI(
      `/building/${params.id}/join`,
      {
        id: params.id,
        companyId: company.id,
      },
      "PUT"
    );
    if (data.status) {
      $crud.notify({
        type: data.status,
        message: data.message,
      });
      const updateProjectList = props.openProjects.map((project) => {
        return project.id === params.id
          ? { ...project, isJoin: true }
          : project;
      });
      props.updateOpenProjects(updateProjectList);
      props.updateProjectAction(true);
      getProjects();
    }
  };
  //Submit RE-join request without company
  const submitReJoinWithoutCompany = async (id) => {
    const { data } = await callAPI(`/building/${id}/join`, {}, "PUT");
    if (data.status) {
      $crud.notify({
        type: data.status,
        message: data.message,
      });
      const updateProjectList = props.openProjects.map((project) => {
        return project.id === params.id
          ? { ...project, isJoin: true }
          : project;
      });
      props.updateOpenProjects(updateProjectList);
      props.updateProjectAction(true);
      getProjects();
    }
  };

  //Search Project
  const searchProject = async (e) => {
    console.log("Search is called")
    const value = e;
    setSearch(value);
    const searchResult = await allProjects.filter((project) => {
      if (value === "") {
        return project;
      } else if (project.name.toLowerCase().includes(value.trim().toLowerCase())) {
        return project;
      }
    });
    if (value === "") {
      setProjects(allProjects);
    } else {
      setProjects(searchResult);
    }
  };


  //Search Kjup plust Projects
  const searchKjuupPlusProject = async (e) => {
    console.log("Search is called")
    const value = e.trim();
    setSearch(value);
    let tmp = [...PlusProject]
    let ind = PlusProject.findIndex(e => e.isSelected)
    let projs = PlusProject[ind].userProjectList
    const searchResult = await projs.filter((project) => {
      if (value === "") {
        return project;
      } else if (project.name.toLowerCase().includes(value.toLowerCase())) {
        return project;
      }
    });
    if (value === "") {
      setKjuupPlusProjects(PlusProjectAll);
    } else {
      tmp[ind] = { ...tmp[ind], userProjectList: [...searchResult] }
      setKjuupPlusProjects(tmp);
    }
  };

  // Open Add User to Project Window
  const addUserToProject = (project) => {
    let ind = -1;
    if (shouldShowTabWise && value == 0) {
      ind = PlusProject.findIndex(e => e.isSelected)
    }
    if (!project.isOpened) {
      const chk = props.openProjects.find((p) => p.id === project.id);
      if (chk) {
        const filteredProject = props.openProjects.filter(
          (p) => p.id !== project.id
        );
        const proj = { ...filteredProject, openAddProjectWindow: true, addAsMember: true };
        props.updateOpenProjects(proj);
      } else {
        props.updateOpenProjects([
          ...props.openProjects,
          { ...project, openAddProjectWindow: true, addAsMember: true },
        ]);
      }
    }
  };

  const handleUpdateRequests = (id, cid, index) => {
    console.log('------->update', id, cid, index)
    if (cid) {
      const updatedRequestsInt = pendingRequestsPlus.filter(
        (req) => req.requestId !== id
      );
      if (updatedRequestsInt.length == 0) {
        setPlusNotifications(false)
      }
      dispatch({ type: SET_JOIN_REQUEST_INTERNAL, requests: updatedRequestsInt })
      let a = { ...unSeenAllCount }
      let f = { ...a, unseenBuildingChatCount: (a.unseenBuildingChatCount - 1) < 0 ? 0 : a.unseenBuildingChatCount - 1, unseenInternalProject: (a.unseenInternalProject - 1) < 0 ? 0 : a.unseenInternalProject - 1 }
      props.updateAllBadgeCount(f);
      let ind = PlusProject.findIndex(e => e.isSelected)
      getKjuupPlusProjects(ind)
      if (ind !== -1) {
        let tmp = [...PlusProject]
        tmp[ind].projectJoinRequests = [...updatedRequestsInt]
        setKjuupPlusProjects([...tmp])
      }
    }
    else {
      const updatedRequests = pendingRequests.filter(
        (req) => req.requestId !== id
      );
      if (updatedRequests.length == 0) {
        setNotification(false)
      }
      dispatch({ type: SET_JOIN_REQUEST_EXTERNAL, requests: updatedRequests })
      props.updateProjectRequestCount(updatedRequests.length);
      let a = { ...unSeenAllCount }
      let f = { ...a, unseenBuildingChatCount: (a.unseenBuildingChatCount - 1) < 0 ? 0 : a.unseenBuildingChatCount - 1, unseenExternalProject: (a.unseenExternalProject - 1) < 0 ? 0 : a.unseenExternalProject - 1 }
      props.updateAllBadgeCount(f);
      getProjects();
    }
  };



  useEffect(() => {
    setValue(props.isExternalSelected)
  }, [props.isExternalSelected])

  const handleChange = (newValue) => {
    props.changeTab(newValue)
    setValue(newValue)
    if (newValue == 0)
      props.changeBorder(KJUUP_COLORS.base_color)
    else
      props.changeBorder(KJUUP_COLORS.internal)
  }

  useEffect(() => {
    if (value == 1) {
      if (PlusProject && PlusProject.length > 0) {
        let ind = PlusProject.findIndex(e => e.isSelected)
        if (ind !== -1) {
          dispatch({ type: SET_COMPANY_IMAGE, payload: PlusProject[ind].logo })
        }
        else {
          dispatch({ type: SET_COMPANY_IMAGE, payload: PlusProject[0].logo })
        }
      }
    }
    else {
      dispatch({ type: SET_COMPANY_IMAGE, payload: null })
    }
  }, [value])


  function LeftArrow() {
    const { isFirstItemVisible, scrollPrev } =
      React.useContext(VisibilityContext);

    return (
      <div className="tt-l mr-2" disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
        {isFirstItemVisible ?
          // <img
          //   width={10} src={process.env.PUBLIC_URL + "assets/img/Arrow Left-deselected.svg"} alt="Generic placeholder"
          // />
          <BsChevronLeft size={'20px'} color={"#DFDFDF"} />
          :
          <BsChevronLeft size={'20px'} color={KJUUP_COLORS.internal} />
          // <img width={10} src={process.env.PUBLIC_URL + "assets/img/backx.svg"} alt="Generic placeholder" />
        }
      </div>
    );
  }

  function RightArrow() {
    const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);

    return (
      <div className="tt-l ml-2" disabled={isLastItemVisible} onClick={() => scrollNext()}>
        {isLastItemVisible ?
          // <img
          //   width={10} src={process.env.PUBLIC_URL + "assets/img/arrow-rightdeselected.svg"} alt="Generic placeholder"
          // />
          <BsChevronRight size={'20px'} color={"#DFDFDF"} />
          :
          <BsChevronRight size={'20px'} color={KJUUP_COLORS.internal} />
        }
      </div>
    );
  }

  const _onCompanyPress = (index, id) => {
    apiRef.current.scrollToItem(
      apiRef.current.getItemById(id),
      // OR if you not sure about id for first item
      // apiRef.current.getItemById(apiRef.current.items.toItems()[0]),
      "smooth",
      "center"
    );
    let tmp = [...PlusProject]
    for (let i = 0; i < tmp.length; i++) {
      if (i == index) {
        if (tmp[i].isLeave) {
          dispatch({ type: SET_COMPANY_IMAGE, payload: null })
        }
        else
          dispatch({ type: SET_COMPANY_IMAGE, payload: tmp[i].logo })
        dispatch({ type: SET_COMPANY_ID, payload: tmp[i].id })
        tmp[i].isSelected = true
      }
      else {
        tmp[i].isSelected = false
      }
    }
    setKjuupPlusProjects(tmp)
    getInternalJoinRequest(id, index)
  }

  const apiRef = React.useRef();

  const _renderProjects = () => {
    return (
      !plusNotifications ?
        <>
          <div
            className="w-300 my_project px-2 pt-0 p-0 h45vh m-0 immoanimation-enter-done border-radius-0"
            style={
              props.isOpenByOwner ? { pointerEvents: "none", opacity: "0.2", height: "94.5vh", backgroundColor: "#edfff8" } : { height: "94.5vh", display: "flex", flexDirection: "column", borderWidth: 0 }
            }
          >
            {PlusProject.length >= 2 &&
              <div style={{ marginTop: 10 }} />
            }
            {PlusProject.length <= 2 ?
              PlusProject.length == 1 ?
                <></>
                :
                <ScrollMenu apiRef={apiRef}>
                  {
                    PlusProject.map((item, index) => {
                      return (
                        <div
                          id={item.id}
                          itemId={item.id}
                          onClick={() => _onCompanyPress(index, item.id)}
                          className="tt-ll"
                          style={{
                            height: 40,
                            width: 200,
                            marginLeft: index == 0 ? 0 : 20,
                            borderColor: item.isLeave ? "lightgray" : item.isSelected ? "#288f6c" : "gray",
                            backgroundColor: item.isLeave ? "#f2f2f2" : item.isSelected ? "#d7f4ea" : "white",
                            fontWeight: "500",
                            // opacity: item.isLeave ? 0.5 : 1,
                            color: item.isLeave ? "white" : "#000"
                            // backgroundColor: "red"
                          }}>
                          <div style={{ display: 'flex', flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                            {item.name}
                            {item?.totalUnreadChat > 0 ?
                              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginLeft: 5, height: "20px", minWidth: "20px", borderRadius: 100, backgroundColor: KJUUP_COLORS.base_color }}>
                                <span style={{ color: "white", fontWeight: 'bold', fontSize: "12px" }}>{item?.totalUnreadChat}</span>
                              </div>
                              :
                              <></>
                            }
                          </div>
                        </div>
                      )
                    })
                  }
                </ScrollMenu>
              : <ScrollMenu apiRef={apiRef} LeftArrow={LeftArrow} RightArrow={RightArrow}>
                {
                  PlusProject.map((item, index) => {
                    return (
                      <div
                        id={item.id}
                        itemId={item.id}
                        onClick={() => _onCompanyPress(index, item.id)}
                        className="tt-ll"
                        style={{
                          height: 40,
                          width: 200,
                          marginLeft: index == 0 ? 0 : 20,
                          borderColor: item.isLeave ? "#000" : item.isSelected ? "#288f6c" : "gray",
                          backgroundColor: item.isLeave ? "#f2f2f2" : item.isSelected ? "#d7f4ea" : "white",
                          fontWeight: "500",
                          opacity: item.isLeave ? 0.5 : 1
                          // backgroundColor: "red"
                        }}>
                        <div style={{ display: 'flex', flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                          {item.name}
                          {item?.totalUnreadChat > 0 ?
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginLeft: 5, height: "20px", minWidth: "20px", borderRadius: 100, backgroundColor: KJUUP_COLORS.base_color }}>
                              <span style={{ color: "white", fontWeight: 'bold', fontSize: "12px" }}>{item?.totalUnreadChat}</span>
                            </div>
                            :
                            <></>
                          }
                        </div>
                      </div>
                    )
                  })
                }
              </ScrollMenu>}
            <div style={{}}>
              {
                PlusProject.map((item, index) => {
                  return (
                    item.isSelected && !item.isLeave &&
                    <>
                      {item.projectJoinRequests && item.projectJoinRequests.length > 0 ?
                        <div style={{ flex: 1 }}>
                          <InvitationProjectsCard onClick={() => {
                            setPendingRequestsPlus(item.projectJoinRequests)
                            setPlusNotifications(true)
                          }} pendingRequests={item.projectJoinRequests.length} />
                        </div>
                        :
                        <></>
                      }
                    </>
                  )
                })
              }
            </div>
            <div style={{ flex: 1 }}>
              {
                PlusProject.map((mainItem, mainIndex) => {
                  return (
                    mainItem.isSelected && mainItem.userProjectList &&
                    <CustomScrollbars
                      autoHide
                      autoHideTimeout={200}
                      autoHideDuration={200}
                      className="bottom-bar"
                    >
                      <ProjectListComponentInternal
                        projects={mainItem.userProjectList ? mainItem.userProjectList : []}
                        chat_click={(val, projectid) => chat_click_plus(val, projectid, value, mainItem.videoCallEnabled)}
                        addUserToProject={(x) => addUserToProject(x)}
                        handleShow={(x) => handleShow(x)}
                        handleJoin={(x, id) => handleJoinInternal(x, id)}
                        isLeave={mainItem.isLeave}
                        cId={mainItem.id}
                      />
                    </CustomScrollbars>
                  )
                })
              }
            </div>
            <ShareProjectMapDialog
              company={projectDetails}
              show={show}
              handleClose={handleClose}
            />
            {showCompanyDialog && (
              <CompanyListDialog
                handleClose={(e) => setCompanyDialog(false)}
                setParam={setParam}
                companies={companies}
                handleReJoinCompany={submitReJoinCompany}
              />
            )}
          </div>
        </>
        :
        <ProjectNotification type={'internal'} updateRequests={handleUpdateRequests} handleClose={() => setPlusNotifications(false)}
          requests={pendingRequestsPlus} />
    )
  }

  const _renderNormalProjects = () => {
    return (
      !showNotification ? (
        <div
          className="w-300 my_project px-2 pt-0 p-0 h45vh m-0 immoanimation-enter-done border-radius-0"
          style={
            props.isOpenByOwner ? { pointerEvents: "none", opacity: "0.2", height: "94.5vh" } : { height: "94.5vh", display: "flex", flexDirection: "column", borderWidth: shouldShowTabWise ? 0 : 2 }
          }
        >
          {/* <div className="main_heading" style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ flex: 1 }} >
              <div className="search m-0"
                style={{ margin: 0, top: 8 }}
              >
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Control
                    autoComplete="off"
                    type="search"
                    onChange={(e) => searchProject(e)}
                    placeholder={isGerm ? DE.search : EN.search}
                  />
                </Form.Group>
                <img
                  src={process.env.PUBLIC_URL + "/assets/img/search.svg"}
                  alt="Generic placeholder"
                />
              </div>
            </div>
            <div>
              <span
                className="position-relative mr-2 ml-1 project-notification-request"
                onClick={() => setNotification(true)}
              >
                <IoIosNotificationsOutline
                  size={30}
                  color="#737373"
                  className="mt-1 pointer"
                />
                {pendingRequests?.length > 0 ? (
                  <div style={{ position: "absolute", top: "-5px", left: "8px", display: "flex", justifyContent: "center", alignItems: "center", marginLeft: 5, height: "20px", minWidth: "20px", borderRadius: 100, backgroundColor: KJUUP_COLORS.base_color }}>
                    <span style={{ color: "white", fontWeight: 'bold', fontSize: "12px" }}>{pendingRequests?.length}</span>
                  </div>
                ) : (
                  ""
                )}
              </span>
              <Link to="/add-project" onClick={() => { props.handleDrawer(true); }}>
                <AddProjectIcon stroke="grey" fill="grey" color="grey" height={27} width={27} />
              </Link>
            </div>
          </div> */}
          {pendingRequests?.length > 0 ?
            <div style={{ flex: 1 }}>
              <InvitationProjectsCard onClick={() => setNotification(true)} pendingRequests={pendingRequests.length} />
            </div>
            :
            <></>
          }

          <CustomScrollbars
            autoHide
            autoHideTimeout={200}
            autoHideDuration={200}
            className="bottom-bar"
          >
            <ProjectListComponent
              projects={projects ? projects : []}
              chat_click={(val, projectid) => chat_click(val, projectid)}
              addUserToProject={(x) => addUserToProject(x)}
              handleShow={(x) => handleShow(x)}
              handleJoin={(x) => handleJoin(x)}
              onChangePosition={(list) => {
                setProjects(list)
              }}
            />
          </CustomScrollbars>
          <ShareProjectMapDialog
            company={projectDetails}
            show={show}
            handleClose={handleClose}
          />
          {showCompanyDialog && (
            <CompanyListDialog
              handleClose={(e) => setCompanyDialog(false)}
              setParam={setParam}
              companies={companies}
              handleReJoinCompany={submitReJoinCompany}
            />
          )}
        </div>
      )
        :
        <ProjectNotification updateRequests={handleUpdateRequests} handleClose={() => setNotification(false)}
          requests={pendingRequests} />
    )
  }

  return (
    <React.Fragment>
      {!shouldShowTabWise ? (
        _renderNormalProjects()
      ) :
        <div
          className="w-300 my_project pt-0 p-0 h45vh m-0 immoanimation-enter-done border-radius-0"
          style={
            props.isOpenByOwner ? { pointerEvents: "none", opacity: "0.2", height: "94.5vh", borderColor: value == 1 ? "#288f6c" : "#f08438" } : { borderColor: value == 1 ? "#288f6c" : "#f08438", height: "94.5vh", display: "flex", flexDirection: "column", overflow: "hidden" }
          }
        >
          <CommonHeader
            value={value}
            onChange={(val) => handleChange(val)}
            isGerm={isGerm}
            searchQuery={search}
            onSearch={(val) => {
              if (value == 0)
                searchProject(val)
              else
                searchKjuupPlusProject(val)
            }}
            showPlus={value == 1 ?
              PlusProject.findIndex(e => e.isSelected) !== -1 ? PlusProject[PlusProject.findIndex(e => e.isSelected)].isLeave ? false : true : false :
              true
            }
            onAddClick={() => {
              props.handleDrawer(true);
              if (value == 1) {
                let mainIndex = PlusProject.findIndex(e => e.isSelected)
                let item = PlusProject[mainIndex]
                console.log('------------->', item)
                history.push(
                  {
                    pathname: '/add-project',
                    state: { from: "internal", companyId: item.id, companyName: item.name, projects: item.userProjectList }
                  })
              }
              else {
                history.push({
                  pathname: `/add-project`,
                });
              }
            }}
          />
          {
            value == 1 ?
              _renderProjects()
              :
              _renderNormalProjects()
          }
        </div>
      }
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  const {
    projects,
    openProjects,
    updateProjectActionFire,
    openBottomProjects,
    projectRequestCount,
    isOpenByOwner,
    isExternalSelected,
    resetProjectMarking
  } = state;
  return {
    projects: projects,
    updateProjectActionFire: updateProjectActionFire,
    openProjects: openProjects,
    openBottomProjects: openBottomProjects,
    projectRequestCount: projectRequestCount,
    isOpenByOwner: isOpenByOwner,
    isExternalSelected,
    resetProjectMarking
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    resetMarking: (e) =>
      dispatch({ type: RESET_MARKIN_PROJECT, status: e }),
    updateProjects: (projects) =>
      dispatch({ type: PROJECTS, projects: projects }),
    updateProjectsInternal: (projects) =>
      dispatch({ type: INTERNAL_PROJECTS, projects: projects }),
    updateOpenProjects: (projects) =>
      dispatch({ type: OPEN_PROJECTS, openProjects: projects }),
    updateProjectAction: (value) =>
      dispatch({
        type: UPDATE_PROJECT_ACTION_FIRE,
        updateProjectActionFire: value,
      }),
    updateBottomOpenProjects: (projects) =>
      dispatch({ type: OPEN_BOTTOM_PROJECTS, openBottomProjects: projects }),
    updateProjectRequestCount: (count) =>
      dispatch({ type: PROJECT_REQUEST_COUNT, projectRequestCount: count }),
    isOwnerOpenProject: (open) => dispatch({ type: IS_OPEN_BY_OWNER, open }),
    handleDrawer: (open) => dispatch({ type: TOGGLE_DRAWER, open }),
    updateAllBadgeCount: (count) =>
      dispatch({ type: ALL_BADGE_COUNT, unSeenAllCount: count }),
    changeTab: (status) => dispatch({ type: SET_TAB, status: status }),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(memo(MyProject)));

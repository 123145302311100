import React, { useEffect, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { callAPI } from "../../helper/apiUtils";
import ShareProjectMapDialog from "../../components/ProjectMapShare/ShareProjectMapDialog";
import {
  OPEN_BOTTOM_PROJECTS,
  OPEN_PROJECTS,
  PROJECT_REQUEST_COUNT,
  PROJECTS,
  RIGHT_TOP,
  UPDATE_PROJECT_ACTION_FIRE,
  IS_OPEN_BY_OWNER,
  OPEN_CHATS,
  OPEN_BOTTOM_CHATS,
  GROUPS,
} from "../../store";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import LanguageContext from "../../helper/LanguageContext";
import { DE } from "../../Languages/DE";
import { EN } from "../../Languages/EN";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import IGRENN from '../../Icons/InternalGreen.png';
import INormal from '../../Icons/Internal-Project.png';
import ExternalOrange from '../../Icons/ExternalOrange.png';
import ExternalNormal from '../../Icons/External-Project.png';
import { async } from "@firebase/util";





const renderThumb = ({ style, ...props }) => {
  const thumbStyle = {
    borderRadius: 6,
    backgroundColor: "rgba(240, 132, 56, 0.8)",
  };
  return <div style={{ ...style, ...thumbStyle }} {...props} />;
};

const CustomScrollbars = (props) => (
  <Scrollbars
    renderThumbHorizontal={renderThumb}
    renderThumbVertical={renderThumb}
    {...props}
  />
);



function UserProjectList(props) {
  const [projects, setProjects] = useState([]);
  const [projectsInternal, setProjectsInternal] = useState([]);
  const [value, setValue] = React.useState(0);

  const [allProjects, setAllProject] = useState([]);
  const [projectDetails, setProjectDetails] = useState({});
  const [show, setShow] = useState(false);
  const { isGerm } = React.useContext(LanguageContext);

  let isKjuup = props.isKjuup
  const get = async () => {
    const { data } = await callAPI(
      `/company/${props.details.companyId}/myEmployeesBuildings`,
      {
        userId: props.details.userId,
        projectType: 0
      },
      "GET"
    );
    if (data.status) {
      const buildings = data.buildings.filter((usr) => usr.isJoin === true);
      setProjects(buildings);
    }
  };

  const getInternal = async () => {
    const { data } = await callAPI(
      `/company/${props.details.companyId}/myEmployeesBuildings`,
      {
        userId: props.details.userId,
        projectType: 1
      },
      "GET"
    );
    if (data.status) {
      const buildings = data.buildings.filter((usr) => usr.isJoin === true);
      console.log(buildings);
      setProjectsInternal(buildings);
    }
  };

  useEffect(() => {
    if (isKjuup) {
      getInternal()
    }
    get();
  }, [props.details]);

  useEffect(() => {
    if (value == 0)
      getInternal()
    else
      get();
  }, [value.toString()]);

  const handleClose = () => setShow(false);
  const handleShow = async (project) => {
    setShow(true);
    setProjectDetails(project);
  };
  const chat_click = async (val, project) => {
    props.updateRightTop("project1");
    const chk = props.openProjects?.find((p) => p.id === project.id);
    if (chk) {
      if (props.openProjects?.length == 1)
        props.isOwnerOpenProject(false);
      const updateProjects = props.openProjects?.filter(
        (p) => p.id !== project.id
      );
      props.updateOpenProjects(updateProjects);
      props.updateProjects(updateProjects);
    } else {
      props.isOwnerOpenProject(true);
      let tempData = props.openProjects
      props.updateOpenProjects([
        ...tempData,
        {
          ...project,
          viewByOwner: true,
          isOpened: true,
          ownerId: props.details.userId,
          isInternal: value == 0 ? true : false
        },
      ]);
      props.updateProjects(tempData);
      props.updateBottomOpenProjects([]);
      props.updateOpenChats([]);
      props.updateOpenBottomChats([]);
      props.updateGroups([]);
    }
  };
  const getProjectName = (name) => {
    if (name) {
      // Remove all non-alphabetic characters (keep only letters)
      const cleanedName = name.replace(/[^a-zA-Z]/g, "");
      return cleanedName.substring(0, 2).toUpperCase();
    }
    return "";
  };

  const onBackButtonClick = () => {
    props.hide();
    let tempData = props.openProjects.filter((data) => data.id === true);
    props.updateProjects(tempData);
    props.updateOpenProjects([]);
    props.isOwnerOpenProject(false);
    props.updateBottomOpenProjects([]);
    props.updateOpenChats([]);
    props.updateOpenBottomChats([]);
  };

  const _renderNormalList = () => {
    return (
      <div className="wscroll right_wscroll px-2" style={{ flex: 1 }}>
        <CustomScrollbars
          autoHide
          autoHideTimeout={200}
          autoHideDuration={200}
          className="bottom-bar"
        >
          <div className="project_listing">
            {projects &&
              projects.map((project) => (
                <div key={project.id} className={`project_info`}>
                  <div
                    onClick={(e) => chat_click(true, project)}
                    className="Project_name"
                  >
                    <div
                      className="project_icon"
                      style={{
                        backgroundColor: project.isJoin
                          ? project.themeColor
                          : "#d9deda",
                      }}
                    >
                      {getProjectName(project.name)}
                    </div>
                    <div
                      className="p_name"
                      style={{
                        color: project.isJoin ? "#737373" : "#d9deda",
                      }}
                    >
                      {project.name}
                      <span>{project.city}</span>
                    </div>
                  </div>
                  <div className="project_action pr-3">
                    <a href="#" onClick={() => handleShow(project)}>
                      <img
                        width={16}
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/img/Location.svg"
                        }
                      />
                    </a>
                  </div>
                </div>
              ))}
          </div>
        </CustomScrollbars>
      </div>
    )
  }

  const _renderIntenalList = () => {
    return (
      <div className="wscroll right_wscroll px-2" style={{ flex: 1 }}>
        <CustomScrollbars
          autoHide
          autoHideTimeout={200}
          autoHideDuration={200}
          className="bottom-bar"
        >
          <div className="project_listing">
            {projectsInternal &&
              projectsInternal.map((project) => (
                <div key={project.id} className={`project_info`}>
                  <div
                    onClick={(e) => chat_click(true, project)}
                    className="Project_name"
                  >
                    <div
                      className="project_icon"
                      style={{
                        backgroundColor: project.isJoin
                          ? project.themeColor
                          : "#d9deda",
                      }}
                    >
                      {getProjectName(project.name)}
                    </div>
                    <div
                      className="p_name"
                      style={{
                        color: project.isJoin ? "#737373" : "#d9deda",
                      }}
                    >
                      {project.name}
                      <span>{project.city}</span>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </CustomScrollbars>
      </div>
    )
  }

  function a11yProps(index, value) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
      style: {
        // backgroundColor: (index == 0 && value == index) ? "yellow" : (index == 1 && value == index) ? "blue" : "white",
        color: (index == 0 && value == index) ? "#288f6c" : (index == 1 && value == index) ? "#f08438" : "grey",
        borderWidth: 0,
        padding: 0,
        textTransform: "none",
        fontSize: "1.8vh",
        // fontWeight: "200"
      }
    };
  }



  const handleChange = (event, newValue) => {
    setValue(newValue);
    // if (newValue == 1)
    //   props.changeBorder(KJUUP_COLORS.base_color)
    // else
    //   props.changeBorder(KJUUP_COLORS.internal)
  }

  return (
    <React.Fragment>
      <div class="company_section w-300 company_list setting_profile company_profile" style={{ height: "93vh", display: "flex", flexDirection: "column" }}>
        <div className="company_header">
          <div className="main_heading">
            <a className="pointer" onClick={onBackButtonClick}>
              <img
                width={10}
                src={process.env.PUBLIC_URL + "/assets/img/back.svg"}
              />
            </a>
            {!isGerm? `Rooms of ${props.details.name}`: `Räume von ${props.details.name}`}
          </div>
        </div>
        {
          !isKjuup ?
            _renderNormalList()
            :
            <>
              <div className="main_heading" style={{ marginBottom: 0, marginBottom: 10 }}>
                <Tabs
                  variant="fullWidth"
                  value={value}
                  onChange={handleChange}
                  TabIndicatorProps={{ style: { background: value == 0 ? "#288f6c" : "#f08438" } }}
                  style={{ width: "100%", }}
                >
                  <Tab icon={
                    value == 1 ?
                      <img
                        id={'myimg'}
                        src={INormal}
                        style={{ height: 25, width: 25, objectFit: "contain", marginBottom: 7 }}
                      />
                      :
                      <img
                        id={'myimg1'}
                        src={IGRENN}
                        style={{ height: 25, width: 25, objectFit: "contain", marginBottom: 7 }}
                      />
                  } iconPosition="top" label={isGerm ? DE.internal_project : EN.internal_project} {...a11yProps(0, value)} />
                  <Tab icon={
                    value == 0 ?
                      <img
                        id={'myimg2'}
                        src={ExternalNormal}
                        style={{ height: 25, width: 25, objectFit: "contain", marginBottom: 7 }}
                      />
                      :
                      <img
                        id={'myimg9'}
                        src={ExternalOrange}
                        style={{ height: 25, width: 25, objectFit: "contain", marginBottom: 7 }}
                      />
                  } iconPosition="top" label={isGerm ? DE.external_projects : EN.external_projects} {...a11yProps(1, value)} />
                </Tabs>
              </div>
              {
                value == 0 ?
                  _renderIntenalList()
                  :
                  _renderNormalList()
              }
            </>
        }
        <ShareProjectMapDialog
          company={projectDetails}
          show={show}
          handleClose={handleClose}
        />
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  const {
    projects,
    openProjects,
    updateProjectActionFire,
    openBottomProjects,
    projectRequestCount,
    rightTop,
    isOpenByOwner,
  } = state;
  return {
    projects: projects,
    rightTop: rightTop,
    updateProjectActionFire: updateProjectActionFire,
    openProjects: openProjects,
    openBottomProjects: openBottomProjects,
    projectRequestCount: projectRequestCount,
    isOpenByOwner,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateProjects: (projects) =>
      dispatch({ type: PROJECTS, projects: projects }),
    updateRightTop: (value) => dispatch({ type: RIGHT_TOP, rightTop: value }),
    updateOpenProjects: (projects) =>
      dispatch({ type: OPEN_PROJECTS, openProjects: projects }),
    updateProjectAction: (value) =>
      dispatch({
        type: UPDATE_PROJECT_ACTION_FIRE,
        updateProjectActionFire: value,
      }),
    updateBottomOpenProjects: (projects) =>
      dispatch({ type: OPEN_BOTTOM_PROJECTS, openBottomProjects: projects }),
    updateProjectRequestCount: (count) =>
      dispatch({ type: PROJECT_REQUEST_COUNT, projectRequestCount: count }),
    isOwnerOpenProject: (open) => dispatch({ type: IS_OPEN_BY_OWNER, open }),
    updateOpenChats: (chats) =>
      dispatch({ type: OPEN_CHATS, openChats: chats }),
    updateOpenBottomChats: (chats) =>
      dispatch({ type: OPEN_BOTTOM_CHATS, openBottomChats: chats }),
    updateGroups: (groups) => dispatch({ type: GROUPS, groups: groups }),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserProjectList));

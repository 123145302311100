import React, { useState, useEffect } from "react";
import { Alert, Button, Card, Form, Modal } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { $user } from "../helper/UserFactory";
import { LoadingDots } from "../LoadingDots/LoadingDots";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { DE } from "../Languages/DE";
import { EN } from "../Languages/EN";
import LanguageContext from "../helper/LanguageContext";
import { EMAIL_REGEX } from "../helper/constants";
import { $crud } from "../helper/CrudFactory";
import { useDispatch } from "react-redux";
import { SET_USER_LOGIN_TOKEN } from "../store";
import { callAPI } from "../helper/apiUtils";
import animationData from '../../src/Icons/Login-Web.json';
import Lottie from 'react-lottie';

function Login(props) {
  const [params, setParams] = useState({
    email: "",
    password: "",
  });
  const { isGerm, setIsGerm } = React.useContext(LanguageContext);
  const [isGerms, setIsGerms] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    // var userLang = navigator.language || navigator.userLanguage;
    let registerLang = localStorage.getItem("regiLang");
    if (registerLang) {
      setIsGerm(registerLang == 2 ? true : false);
      setIsGerms(registerLang == 2 ? true : false);
      setLanguage(registerLang == 2 ? "DE" : "EN");
    }
  }, []);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [isInvalid, setIsInvalid] = useState(false);
  const [invalidMsg, setInvalidMsg] = useState("Invalid Credentials.");
  const history = useHistory();
  const [popup, setPopup] = useState(false);
  const [isReactive, setIsReactive] = useState(false);
  const [viewPassword, setViewPassword] = useState(false);
  const [language, setLanguage] = React.useState("DE");

  const login = async () => {
    let tokenx = localStorage.getItem("firebaseToken")
    setLoading(true);
    setIsInvalid(false);
    if (handleValidation()) {
      try {
        let loginData;
        if (EMAIL_REGEX.test(params.email))
          loginData = { email: params.email, password: params.password };
        else
          loginData = { username: params.email, password: params.password };
        $user.login({ ...loginData, fcmId: tokenx }).then(async (res) => {
          if (!res.status) {
            if (!res.isPinCheck) {
              if (res.hasOwnProperty("isEnable")) {
                setPopup(true);
                setInvalidMsg(res.message);
              } else {
                setInvalidMsg(res.message);
                setIsInvalid(true);
                setLoading(false);
              }
            }
            else {
              setIsReactive(true)
            }
          } else {
            localStorage.setItem("switch",res?.user?.language == "de" ? "2" : "1");
            if(res?.user?.language == "de"){
              setIsGerm(true);
              setIsGerms(true);
            }
            else{
              setIsGerm(false);
              setIsGerms(false);
            }
            localStorage.removeItem("regiLang")
            console.log("res=>", res);
            dispatch({ type: SET_USER_LOGIN_TOKEN, status: res.token });
            getProfile()
            history.push("/articles");
          }
        });
      } catch (e) {
        console.log(e);
      }
    }
    setLoading(false);
  };


  const getProfile = async () => {
    const { data } = await callAPI(`/user/profile`, {}, "GET");
    let profile = data?.profile;
    $user.updateUserData({ ...profile });
  };

  const reactiveAccount = async () => {
    history.push({
      pathname: '/security-pin',
      state: {
        isResetAccount: true,
        username: params.email,
        password: params.password
      }
    })
  }

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;
    //Name
    if (!params.email) {
      formIsValid = false;
      errors["email"] = isGerms ? DE.invalid_fiels : EN.invalid_fiels;
    }
    const passwordRegex = /(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+|}{:"?><,./;'\[\]\\\-]).{6,}/;
    //Password
    if (!params.password) {
      formIsValid = false;
      errors["password"] = isGerms ? DE.pass_minimum : EN.pass_minimum;
    }
    setErrors(errors);
    return formIsValid;
  };

  const setParam = async (name, value) => {
    await setParams((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
    setIsInvalid(false);
    setErrors((prev) => {
      return {
        ...prev,
        [name]: "",
      };
    });
  };

  const handleClose = async () => {
    setPopup(false);
    setIsReactive(false)
  };
  const handleChangeLanguage = async (e) => {
    console.log("e.target.value=>", e.target.value);
    let lang = e.target.value;
    lang === "DE"
      ? localStorage.setItem("switch", "2")
      : localStorage.setItem("switch", "1");
    setIsGerm(!isGerms);
    setIsGerms(!isGerms);
    setLanguage(lang);
    lang === "DE"
      ? localStorage.setItem("regiLang", "2")
      : localStorage.setItem("regiLang", "1");
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      // preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <React.Fragment>

      <div className="main-div sing_in_with-img">
        {/* <div className="side_img"> */}
          {/* <img
            src={process.env.PUBLIC_URL + "/assets/img/sign-in-mobile.gif"}
          /> */}
          <Lottie options={defaultOptions}
            height={520}
            width={520}
            isStopped={false}
            isPaused={false}
          // isStopped={this.state.isStopped}
          // isPaused={this.state.isPaused} 
          />
        {/* </div> */}
        {/* <div className="app_icon">
        
      </div> */}
        <div className="w-300 sign_in_transprent">
          <div className="app_icon">
            <Card.Body style={{ padding: "0" }}>
              <Form.Group>
                <Form.Control
                  value={language}
                  onChange={(e) => {
                    handleChangeLanguage(e);
                  }}
                  as="select"
                  style={{ width: "110px", margin: "0 auto" }}
                >
                  <option value="EN">{isGerms ? DE.english : EN.english}</option>
                  <option value="DE">{isGerms ? DE.german : EN.german}</option>
                </Form.Control>
              </Form.Group>
            </Card.Body>
          </div>

          <div className="w-300 add_company login_singup">
            <div className="heading">
              <h2>
                <img
                  height="47px"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/img/KJUUP-Schrift-Icon.png"
                  }
                />
              </h2>
            </div>
            <div className="sub_heading">
              <h3>{isGerms ? DE.sign_in : EN.sign_in}</h3>
            </div>
            <div className="form-bottom">
              <Form
                className="add_company_from"
                onSubmit={(e) => {
                  e.preventDefault();
                  login();
                }}
              >
                <Form.Group controlId="formBasicEmail">
                  <Form.Control
                    value={params.email}
                    onChange={(e) => setParam("email", e.target.value)}
                    type="text"
                    placeholder={
                      isGerms
                        ? DE.email_username
                        : EN.email_username
                    }
                  />
                  {errors.email && (
                    <small className="pl-2 text-danger">{errors.email}</small>
                  )}
                </Form.Group>
                <Form.Group controlId="formBasicPassword">
                  <Form.Control
                    value={params.password}
                    onChange={(e) => setParam("password", e.target.value)}
                    type={viewPassword ? "text" : "password"}
                    placeholder={isGerms ? DE.password : EN.password}
                  />
                  {!viewPassword && (
                    <BsFillEyeSlashFill
                      className="pointer"
                      onClick={(e) => {
                        e.preventDefault();
                        setViewPassword(true);
                      }}
                    />
                  )}
                  {viewPassword && (
                    <BsFillEyeFill
                      className="pointer"
                      onClick={(e) => {
                        e.preventDefault();
                        setViewPassword(false);
                      }}
                    />
                  )}
                  {errors.password && (
                    <small className="text-danger">
                      {errors.password}
                    </small>
                  )}
                </Form.Group>
                <div className="text-right small">
                  <Link to="/forgot-password">
                    {isGerms ? DE.forgot_password : EN.forgot_password}?
                  </Link>
                </div>
                {isInvalid && (
                  <div className="p-2 text-center small">
                    <Alert variant="danger">{invalidMsg}</Alert>
                  </div>
                )}
                <Button
                  disabled={loading}
                  type="submit"
                  className="btn request-btn"
                >
                  {loading ? (
                    <LoadingDots />
                  ) : isGerms ? (
                    DE.sign_in
                  ) : (
                    EN.sign_in
                  )}
                </Button>
              </Form>
            </div>
            <div className="or">
              <span>{isGerms ? DE.or : EN.or}</span>
            </div>
            <div className="register_link">
              <p>{isGerms ? DE.dont_have_account : EN.dont_have_account}</p>
              <Link to="/register">{isGerms ? DE.register : EN.register}</Link>
            </div>
          </div>
          <div className="app_icon">
            <h2>{isGerms ? DE.get_app : EN.get_app}</h2>
            <div className="app_img">
              <a
                href="https://apps.apple.com/de/app/immosnapp/id1544915778"
                target="_blank"
              >
                <img src={process.env.PUBLIC_URL + "/assets/img/ios.svg"} />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=de.jft.immosnapp"
                target="_blank"
              >
                <img
                  src={process.env.PUBLIC_URL + "/assets/img/playstore.png"}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* <div style={{ marginTop: 15 }}>
        <a
          href="https://www.kjuup.de/impressum-agb"
          target="_blank"
          style={{ color: "#737373" }}
        >
          {isGerms ? DE.imprint_gtc : EN.imprint_gtc}
        </a>
      </div> */}
      <Modal show={popup} onHide={handleClose}>
        <Modal.Body>{invalidMsg}</Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button size="sm" variant="primary" onClick={handleClose}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={isReactive} onHide={handleClose}>
        <Modal.Body>{isGerms ? DE.reactive_text : EN.reactive_text}</Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="secondary" onClick={handleClose}>
            {isGerms ? DE.cancel : EN.cancel}
          </Button>
          <Button size="sm" variant="primary" onClick={reactiveAccount}>
            {isGerms ? DE.rac : EN.rac}
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default Login;

import React, {useEffect, useState} from 'react';
import {Form} from 'react-bootstrap';
import {Link, useHistory} from 'react-router-dom';
import {DE} from '../../Languages/DE';
import {EN} from '../../Languages/EN';
import LanguageContext from '../../helper/LanguageContext';
import Layout from '../../components/Layout';
import ShareProjectMapDialog from "../../components/ProjectMapShare/ShareProjectMapDialog";
import {callAPI} from "../../helper/apiUtils";
import {$crud} from "../../helper/CrudFactory";
import _ from "lodash";
import CompanyListDialog from "./Dialogs/CompanyListDialog";
import {BASE_URL} from "../../helper/constants";


function ProjectDetail(props) {
    const [params, setParams] = useState({
        companyId: "",
        id: '',
        companySelect: false
    });
    const [show, setShow] = useState(false)
    const [showCompanyDialog, setCompanyDialog] = useState(false)
    const [showMember, setShowMember] = useState(false);
    const [projectDetails, setProjectDetails] = useState({})
    const handleShow = async (project) => {
        setShow(true);
        setProjectDetails(project)
    }
    const handleClose = () => setShow(false);
    const [users, setUsers] = useState([])
    const {isGerm} = React.useContext(LanguageContext)
    const history = useHistory();
    const id = props.match.params.id

    const getProjectDetails = async () => {
        const {data} = await callAPI(`/building/one/${id}`, {
            id: id
        }, 'GET')
        if (data.status) {
            await setProjectDetails(data)
        }
    }
    const [companies, setCompanies] = useState([])

    const getCompanies = async () => {
        const {data} = await callAPI(`/company/my`, {}, 'GET')
        if (data.status) {
            setCompanies(data.companies)
        }
    }
    const getMembers = async () => {
        const id = props.match.params.id
        const {data} = await callAPI(`/building/${id}/user`, {}, 'GET');
        if (data.status) {
            await CreateList(data.members)
        }
    }
    const CreateList = async (users) => {
        const arr = await _.uniqBy(users.map((user) => {
            let name = {name: user.lastName.trim()[0].toUpperCase()}
            return name;
        }), 'name');
        const updatedArr = _.sortBy(arr, ["name"]);
        const usersList = updatedArr.map((a) => {
            let names = users.filter(u => u.lastName.trim()[0].toUpperCase() === a.name)
            a.names = names;
            return a;
        })
        setUsers(usersList)
    }

    useEffect(() => {
        getProjectDetails();
        getMembers()
        getCompanies();
    }, [])

    const getProjectName = (name) => {
        if (name) {
            // Remove all non-alphabetic characters (keep only letters)
            const cleanedName = name.replace(/[^a-zA-Z]/g, "");
            return cleanedName.substring(0, 2).toUpperCase();
          }
          return "";
      };
    const leaveProject = async (id) => {
        await $crud.confirm({
            title: "Are you sure?",
            textContent: "Do you really want to leave the room? When leaving the room you will also leave all associated message groups.",
            options: {
                ok: "Yes",
                cancel: "No"
            }
        });
        const {data} = await callAPI(`/building/${id}/leave`, {
            id: id
        }, 'GET')
        if (data.status) {
            $crud.notify({
                type: data.status ? 'success' : 'error',
                message: data.message
            })
            history.push({
                pathname: '/project-list'
            })
        }
    }
    const setParam = async (name, value) => {
        setParams(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }

    const handleJoin = async (project) => {
        await $crud.confirm({
            title: "",
            textContent: `Do you really want to re-join the room ${project.name}?`,
            options: {
                ok: "Confirm",
                cancel: "Cancel"
            }
        });
        setParam('id', project.id)
        setCompanyDialog(true);
    }
    const submitReJoinCompany = async () => {
        const {data} = await callAPI(`/building/${params.id}/join`, params, 'PUT')
        if (data.status) {
            $crud.notify({
                type: data.status,
                message: data.message
            })
            getProjectDetails();
        }
    }

    return (
        <React.Fragment>
            <Layout>
                <div className="w-300 my_project_user">
                    <div className="user_name bg-white-user">
                        <div className="row d-flex justify-content-between align-items-center">
                            <div className="col-md-9 pr-0">
                                <div className="user_profile">
                                    <div className="user_img text-truncate">
                                        <Link to='/project-list' className="float-left mt-2">
                                            <img className="img-fluid"
                                                 src={process.env.PUBLIC_URL + "/assets/img/back.svg"}
                                                 alt="Generic placeholder"/>
                                        </Link>
                                        <div className="projectIcon"
                                             style={{backgroundColor: projectDetails?.isJoin ? projectDetails?.themeColor : '#d9deda'}}>{getProjectName(projectDetails?.name)}</div>
                                        <p style={{color: projectDetails?.isJoin ? '#737373' : '#d9deda'}}>{projectDetails?.name}</p>


                                    </div>

                                </div>
                            </div>
                            <div className="col-md-3 pl-0">
                                <div className="follow justify-content-end">
                                    {
                                        !projectDetails?.isJoin &&
                                        <div className="follow_item text-center">
                                            <a href="#" onClick={e => handleJoin(projectDetails)}>
                                                <img width={22}
                                                     src={process.env.PUBLIC_URL + "/assets/img/Add.svg"}/>
                                            </a>
                                        </div>
                                    }
                                    <div className="follow_item text-center">
                                        <a href="#" onClick={e => handleShow(projectDetails)}>
                                            <img width={16}
                                                 src={process.env.PUBLIC_URL + "/assets/img/Location.svg"}/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"showsmlinfo details_inner bg-white-user"}>
                        <div className="details-item">
                            <p style={{color: projectDetails?.isJoin ? '#737373' : '#d9deda'}}>{projectDetails?.street}</p>
                            <p style={{color: projectDetails?.isJoin ? '#737373' : '#d9deda'}}>{projectDetails?.city}</p>
                            {projectDetails?.projectNo ?
                                <p style={{color: projectDetails?.isJoin ? '#737373' : '#d9deda'}}>Project
                                    No.: {projectDetails?.projectNo}</p> : ''}
                            <p style={{color: projectDetails?.isJoin ? '#737373' : '#d9deda'}}>Opened by: <span
                                style={{color: projectDetails?.isJoin ? '#f08438' : '#d9deda'}}>{projectDetails?.openedBy}</span>
                            </p>
                        </div>
                        <Link to={`/edit-project/${projectDetails?.id}`}>
                            <img width={20} src={process.env.PUBLIC_URL + "/assets/img/edit-pen-s.svg"}
                                 alt="Generic placeholder"/>
                        </Link>
                    </div>
                    <div className="select_icon bg-white-user">
                        <div className="mt-10">
                            <h3 className="middle-title pointer" onClick={e => {
                                setShowMember(!showMember)
                            }}><span> <img width={22}
                                           src={process.env.PUBLIC_URL + "/assets/img/project-member.svg"}/></span>
                            </h3>
                        </div>

                        <Link to={`/invite-project-user/${id}`}>
                            <img width={22} src={process.env.PUBLIC_URL + "/assets/img/Link-deselected.svg"}
                                 alt="Generic placeholder"/>
                        </Link>
                        <Link to={`/add-project-user/${id}`}>
                            <img width={28} src={process.env.PUBLIC_URL + "/assets/img/add-to-project.svg"}
                                 alt="Generic placeholder"/>
                        </Link>
                        <Link to={`/media-links-docs/${id}`}>
                            <img width={32} src={process.env.PUBLIC_URL + "/assets/img/Media-Links-Docs.svg"}
                                 alt="Generic placeholder"/>
                        </Link>
                    </div>
                    {
                        showMember &&
                        <div className="admin-content m-0">
                            {
                                users &&
                                users.map((user, index) => (
                                    <div key={index}>
                                        <h5 className="p-2" style={{background: '#f7f7f7'}}>{user.name}</h5>
                                        {
                                            user.names &&
                                            user.names.map((u, i) => (
                                                <div key={i} className="">
                                                    <div className="row m-0">
                                                        <div className="col-md-2 p-2">
                                                            <div className="admin-control">
                                                                <img width={47} height={47}
                                                                     src={u.avatar ? BASE_URL + u.avatar : process.env.PUBLIC_URL + "/assets/img/head.svg"}
                                                                     alt="Generic placeholder"/>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-10 p-2">
                                                            <p className="small font-weight-bold m-0">{u.fullName}</p>
                                                            <p className="m-0 small">{u.companyName}</p>
                                                            <p className="m-0 small">Position: {u.position}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))

                                        }

                                    </div>
                                ))
                            }

                        </div>
                    }
                    <div className="chats_user bg-white-user">
                        <h5>Chats
                            <a href="#">
                                <img width={20} src={process.env.PUBLIC_URL + "/assets/img/create-article.svg"}
                                     alt="Generic placeholder"/>
                            </a>
                        </h5>
                        <div className="search">
                            <Form.Group controlId="exampleForm.ControlInput1">
                                <Form.Control type="email" placeholder={isGerm ? DE.search : EN.search}/>
                            </Form.Group>
                            <img src={process.env.PUBLIC_URL + "/assets/img/search.svg"} alt="Generic placeholder"/>
                        </div>
                        <div className="company_services chat_services">
                            <div className="company_centact">
                                <div className="website">
                                    <div className="website_img">
                                        <img src={process.env.PUBLIC_URL + "/assets/img/is.png"}
                                             alt="Generic placeholder"/>
                                    </div>
                                    <div className="details-company">
                                        <h5>Interne Abstimmung Stadt <span className="date">Gestern</span></h5>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting
                                            industry.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="company_centact">
                                <div className="website">
                                    <div className="website_img">
                                        <img src={process.env.PUBLIC_URL + "/assets/img/is.png"}
                                             alt="Generic placeholder"/>
                                    </div>
                                    <div className="details-company">
                                        <h5>Interne Abstimmung Stadt</h5>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting
                                            industry.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="company_centact">
                                <div className="website">
                                    <div className="website_img">
                                        <img src={process.env.PUBLIC_URL + "/assets/img/is.png"}
                                             alt="Generic placeholder"/>
                                    </div>
                                    <div className="details-company">
                                        <h5>Interne Abstimmung Stadt</h5>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting
                                            industry.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="project_verson">

                        </div>
                    </div>
                    <div class="leave_company pl-3 pr-3">
                        <h5 className="svg_icon" onClick={e => leaveProject(projectDetails?.id)}>
                            <svg className="mr-3" width="17px" xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 10.64 10.64">
                                <line x1="0.32" y1="0.32" x2="10.32" y2="10.32"/>
                                <line class="cls-1" x1="10.32" y1="0.38" x2="0.32" y2="10.32"/>
                            </svg>
                            {isGerm ? DE.leave_project:EN.leave_project}
                        </h5>
                    </div>
                    <ShareProjectMapDialog company={projectDetails} show={show} handleClose={handleClose}/>
                    {
                        showCompanyDialog &&
                        <CompanyListDialog handleClose={e => setCompanyDialog(false)} setParam={setParam}
                                           companies={companies} handleReJoinCompany={submitReJoinCompany}/>
                    }
                </div>
            </Layout>
        </React.Fragment>
    );
}

export default ProjectDetail;

import React, { useState } from 'react'
import { AiOutlineFile } from 'react-icons/ai'
import { BsDownload } from 'react-icons/bs'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { BASE_URL, KJUUP_COLORS } from '../helper/constants';
import axios from 'axios';
import './DND.css'
import { useSelector } from 'react-redux';

function DownloadFileWithIcon({ info, group, isMe }) {
    console.log('info', info, group, isMe)
      const { user } =
        useSelector((state) => state);
    let url = (Array.isArray(info.fileUrl) && info.fileUrl.length > 0) ? info.fileUrl[0] : ''
    const [progress, setProgress] = useState(0)
    const _dowloadFile = (url) => {
        axios({
            url: BASE_URL + url,
            method: "GET",
            responseType: "blob", // important
            onDownloadProgress: (progressEvent) => {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total); // you can use this to show user percentage of file downloaded
                if (percentCompleted == 100) {
                    setProgress(0)
                }
                else {
                    setProgress(percentCompleted)
                }
            },
        })
            .then(response => {
                function getFile(filePath) {
                    return filePath?.split('/').pop();
                }
                function getoutput() {
                    let _url = BASE_URL + url
                    let name = getFile(_url);
                    let extension = _url?.split('.').pop();
                    if (info.attachedFileName)
                        return info.attachedFileName.replace(extension, '').replace('.', '') + "." + extension
                    else {
                        return name + "." + extension
                    }
                }
                const href = URL.createObjectURL(response.data);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', getoutput());
                document.body.appendChild(link);
                link.click()
            })
            .catch(err => {
                console.log(err)
            })
    }
    return (
        <div onClick={() => {
            if (!progress)
                _dowloadFile(url)
        }} style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", marginBottom:info?.senderId==user.id?0: 15 }}>
            <div style={{ flex: 1, display: "flex", flexDirection: 'row', justifyContent: "center", alignItems: "center" }}>
                <AiOutlineFile size={30} />{" "}
                <span style={{ marginLeft: 5, fontSize: '15px' }}>
                    {
                        info.attachedFileName
                    }
                </span>
                <div style={{ textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center", marginLeft: 10 }}>
                    <CircularProgressbar
                        value={progress}
                        text={progress ? `${progress}%` : ''}
                        styles={{
                            // Customize the root svg element
                            root: { height: 30, width: 30, fontSize: 25 },
                            // Customize the path, i.e. the "completed progress"
                            path: {
                                // Path color
                                stroke: KJUUP_COLORS.base_color,
                                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                strokeLinecap: 'butt',


                            },
                            // Customize the text
                            text: {
                                // Text color
                                fill: KJUUP_COLORS.base_color,
                                // Text size
                                fontSize: 20,
                            },
                            // Customize background - only used when the `background` prop is true
                            background: {
                                fill: '#3e98c7',
                            },
                        }}
                    />
                    {!progress ?
                        <div style={{ position: "absolute", }}>
                            <BsDownload size={13} color={"#000"} />
                        </div>
                        :
                        <></>
                    }
                </div>
            </div>
            <div style={{ width: 25 }} />
        </div>
    )
}

export default DownloadFileWithIcon